import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const FollowLinks = () => {

  const data = useStaticQuery(graphql`
    query {
      twitter: file(relativePath: { eq: "tweet.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rss: file(relativePath: { eq: "subscribe.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <section
      id='follow-links'
      className='flex justify-between items-center w-full flex-col md:flex-row'>
      <a
        href="https://twitter.com/dylanhorrocks"
        target="_blank"
        rel="noreferrer noopener"
        title="twitter"
        className='w-3/5 sm:w-1/3 md:w-1/4 my-2'>
        <Img
          fluid={data.twitter.childImageSharp.fluid}
          alt='handdrawn twitter icon'/>
      </a>
      <a
        href="/rss"
        target="_blank"
        rel="noreferrer noopener"
        title="rss"
        className='w-2/5 sm:w-1/5 md:w-1/6 my-2'>
        <Img
          fluid={data.rss.childImageSharp.fluid}
          alt='handdrawn rss icon'
          className='max-w-full'/>
      </a>
    </section>
  );
};

export default FollowLinks;
