import React from 'react';
import DirectoryNav from '../components/directory-nav.js';
import FollowLinks from '../components/follow-links.js';
import LatestUpdates from '../components/latest-updates.js';
import Seo from '../components/seo';

const IndexPage = () => (
  <>
    <Seo title='Home' />
    <blockquote className='p-8 flex flex-col md:flex-row text-l italic'>
      Comics erode the most fundamental habits of humane, civilized living.
      <footer className='ml-2'><a href='https://www.read-nz.org/writer/pearson-bill/' title='bio of bill pearson from read-nz.org' target='_blank' rel='noopener noreferrer'>- Bill Pearson</a></footer>
    </blockquote>
    <DirectoryNav />
    <FollowLinks />
    <LatestUpdates />
  </>
);

export default IndexPage;
