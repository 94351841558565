import React from 'react';
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import dayjs from 'dayjs';
import contentParser from 'gatsby-wpgraphql-inline-images';

// this is to help contentParser,
// which makes any images in our wordpress site are cached locally on build.
const pluginOptions = {
  wordPressUrl: 'https://wp.apileof.rocks/',
  uploadsUrl: "http://wp.apileof.rocks/wp-content/uploads"
};

// regex that will match ~~BREAK~~ or ~~Break~~ or ~~bReAk~~...you get it ;)
let breakSymbol = new RegExp(/<p>~~break~~<\/p>/i);

// template for each entry in our list of updates.
const Update = ({update, divider, lastItem}) => {
  let {
    content,
    title,
    postId,
    date
  } = update;

  let publicationDate = dayjs(date).format(`DD MMMM, YYYY`);
  let hasBreak =  breakSymbol.test(content);
  let text = hasBreak
      ? content.split(breakSymbol)[0]
      : content;

  return (
    <li className="mt-12">
      <header className="flex flex-col md:flex-row justify-between items-center">
    <Link to={`/news/${postId}`} className="no-underline">
        <h3 className="text-5xl">{title}</h3>
    </Link>
        <time className="font-thin smallcaps sans">{publicationDate}</time>
      </header>
      <article className="mb-16 md:mb-20">
        {contentParser({ content: text }, pluginOptions)}
        {hasBreak &&
         <Link to={`/news/${postId}`} className="text-center my-2 block font-bold no-underline text-xl">
           Read More >>
         </Link>
        }
        <Link to={`/news/${postId}#comments`} className='text-center my-2 block font-bold no-underline text-xl'>
           Leave a Comment >>
        </Link>
      </article>
      {!lastItem &&
       <div>
         <Img fluid={divider} alt="hand-drawn ribbon that stretches length of post" className="w-full my-8 md:my-16 m-auto"/>
       </div>
      }
    </li>
  )
};

// the whole list
const LatestUpdates = () => {
  const data = useStaticQuery(graphql`
  query {
    updates: wpgraphql {
      posts(first: 5) {
        nodes {
          excerpt
          content
          postId
          date
          title
        }
      }
    }
    updateImage: file(relativePath: { eq: "updates.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    divider1: file(relativePath: { eq: "divider1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    divider2: file(relativePath: { eq: "divider2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    archiveImage: file(relativePath: { eq: "news-archives.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  }
`)

  let {
    updates: {
      posts: {
        nodes
      }
    }
  } = data;

  return (
    <section id='latest-updates' className='my-12'>
      <h2 className='hidden'>Latest Updates</h2>
      <Link to='/news' className='m-8'>
        <Img fluid={data.updateImage.childImageSharp.fluid} alt='handdrawn rss icon' className='w-3/5 md:w-2/5 m-auto'/>
      </Link>
      <ul>
        {nodes.map((node, index) => (
          <Update
            update={node}
            key={node.postId}
            divider={((index % 2) !== 0)
                     ? data.divider1.childImageSharp.fluid
                     : data.divider2.childImageSharp.fluid}
            lastItem={index === (nodes.length - 1)}
          />
        ))}
      </ul>
      <Link to='/news' className='m-8'>
        <Img fluid={data.archiveImage.childImageSharp.fluid} className="w-3/5 md:w-2/5 m-auto"/>
      </Link>
    </section>
)
};

export default LatestUpdates;
